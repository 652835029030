/* app/controls/control.testimonials-rotator */

import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';
import 'flickity-imagesloaded';

let $testimonialsRotators;

const arrowShape = {
	x0: 10,
	x1: 60, y1: 50,
	x2: 65, y2: 45,
	x3: 20
};

const testimonialsRotatorSettings = {
	arrowShape: arrowShape,
	wrapAround: true,
	lazyLoad: true,
	pageDots: false
};

var TestimonialsRotator = {
	init: function($elms) {

		$testimonialsRotators = $elms;

		for (let i = 0; i < $testimonialsRotators.length; i++) {
			TestimonialsRotator._initFlickity($testimonialsRotators[i]);
		}
	},

	_initFlickity: function(testimonialsRotator) {
		const thisFlick = new Flickity(testimonialsRotator, testimonialsRotatorSettings);
	}
};

export { TestimonialsRotator };