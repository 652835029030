/* app/controls/control.testimonials-rotator */

import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';
import 'flickity-imagesloaded';

let $HerosRotators;

const arrowShape = {
	x0: 10,
	x1: 60, y1: 50,
	x2: 65, y2: 45,
	x3: 20
};

const HerosRotatorSettings = {
	arrowShape: arrowShape,
	wrapAround: true,
	lazyLoad: true,
	pageDots: true,
	autoPlay: 4000
};

var HerosRotator = {
	init: function ($elms) {

		$HerosRotators = $elms;

		for (let i = 0; i < $HerosRotators.length; i++) {
			HerosRotator._initFlickity($HerosRotators[i]);
		}
	},

	_initFlickity: function (HerosRotator) {
		const thisFlick = new Flickity(HerosRotator, HerosRotatorSettings);
	}
};

export { HerosRotator };