/* app/ui/nav/large */

import $ from 'jquery';
import * as Core from 'Util/core';
import { publish, subscribe } from 'Util/pubsub';

var NavLarge;
var $nav;
var selectors;
var classes;

var navHeight;
var navPos;
var topPos;

var $window;
var $body;
var $anchorLinks;
var $navWithMenus;

var NavLarge = {
	init: function($elm, selectorList, classList) {
		NavLarge = this;
		$nav = $elm;
		selectors = selectorList;
		classes = classList;

		NavLarge._initElements();
		NavLarge._setSizes();
		NavLarge._initSubscriptions();
		NavLarge._initEvents();
	},

	_setSizes: function() {
		navHeight = $nav.outerHeight();
		navPos = $nav.offset().top;
	},

	_initElements: function () {
		$window = $(window);
		$body = $('body');
		$navWithMenus = $nav.find(selectors.navItemMega);
		$anchorLinks = $('.js-anchor-link');
	},

	// Listen for window resize event (listener bound on all.js)
	_initSubscriptions: function () {
		//subscribe('/window/resize', NavLarge._setSizes);
	},

	_initEvents: function() {
		$anchorLinks.on('click', NavLarge._processAnchorClick);

		this._bindClickEvents();
	},

	_toggleMegaMenu: function(target, click, event) {
		if (click === 'click') {
			event.preventDefault();
		}
		var $thisTarget = $(target);
		var $thisNav = $thisTarget.is(selectors.navItemMega) ? $thisTarget : $thisTarget.closest(selectors.navItemMega);
		var $expanded = $nav.find(selectors.navItemMega).filter('[aria-expanded="true"]');

		if (click && click === 'click' && $expanded.find(selectors.megamenuHeading)[0] !== target) {
			$expanded.attr('aria-expanded', false);
			$expanded.addClass(classes.allowAnimations);
		}

		if ($thisNav.attr('aria-expanded') === 'true') {
			$thisNav.attr('aria-expanded', false);
			$thisNav.addClass(classes.allowAnimations);
		} else {
			$thisNav.attr('aria-expanded', true);
			$thisNav.addClass(classes.allowAnimations);
		}
	},

	unbind: function () {
		$anchorLinks.off('click', NavLarge._processAnchorClick);
		$navWithMenus.off('click', this._toggleMegaMenu);
		$navWithMenus.off('mouseenter').off('mouseleave');

		this._unbindClickEvents();
	},

	_toggleMegaMenuClick: function(event) {
		publish('/nav/navToggle');
		NavLarge._toggleMegaMenu(this, 'click', event);
	},

	_processAnchorClick: function(event) {
		event.preventDefault();

		var hash = this.href.split('#')[1];
		var $anchor = $('#' + hash);

		if ($anchor.length) {
			Core.scrollToElm($anchor, navHeight -1);
		}
	},

	_bindClickEvents: function() {
		$navWithMenus.each(function () {

			//detect if touch or mouse
			if (window.matchMedia("(any-pointer: coarse)").matches) {
				$(this).find(selectors.megamenuHeading).on('click', NavLarge._toggleMegaMenuClick);
			} else {
				$(this).find(selectors.megamenuHeading).on('mouseenter', NavLarge._toggleMegaMenuClick);
				$(".c-navigation__items").on('mouseleave', NavLarge._toggleMegaMenuClick);
			}
		});

		$('body').on('click', NavLarge._handleBodyClick);
	},

	_unbindClickEvents: function() {
		$navWithMenus.each(function() {
			$(this).find(selectors.megamenuHeading).off('click', NavLarge._toggleMegaMenuClick);
		});
		$('body').off('click', NavLarge._handleBodyClick);
	},

	_handleBodyClick: function(e) {
		var $target = $(e.target);
		var $expandedItem;

		if (!$target.closest(selectors.nav).length && $nav.find(selectors.navItemMega).filter('[aria-expanded="true"]').length) {
			$expandedItem = $nav.find(selectors.navItemMega).filter('[aria-expanded="true"]');

			$expandedItem.attr('aria-expanded', false);
			$expandedItem.addClass(classes.allowAnimations);
		}
	}
};

export { NavLarge };
